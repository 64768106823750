import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This blog post is a first draft of documentation I'm working on. It covers how the JavaScript Agent works, our recommended workflows, and how to get set up with various tools and environments.`}</p>
    <h2 {...{
      "id": "basics"
    }}><a parentName="h2" {...{
        "href": "#basics"
      }}>{`Basics`}</a></h2>
    <p>{`To get started with JavaScript on the Internet Computer, we recommend you follow our quickstart guide in order to get set up with the basics of your development environment. This includes:`}</p>
    <ul>
      <li parentName="ul">{`Dfinity's development SDK, `}<inlineCode parentName="li">{`dfx`}</inlineCode></li>
      <li parentName="ul">{`Node JS (12, 14, or 16)`}</li>
      <li parentName="ul">{`A canister you want to experiment with`}
        <ul parentName="li">
          <li parentName="ul">{`Suggestions: `}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`dfx new`}</inlineCode>{` starter project`}</li>
              <li parentName="ul">{`an example from `}<a parentName="li" {...{
                  "href": "https://github.com/dfinity/examples"
                }}>{`dfinity/examples`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <p>{`The Internet Computer blockchain is accessible via an API that is available at `}<a parentName="p" {...{
        "href": "https://ic0.app/api/v2"
      }}>{`https://ic0.app/api/v2`}</a>{`. Smart contracts are able to define their own API's using the Candid Interface Declaration Language (IDL), and they will respond to calls through the public API.`}</p>
    <p>{`The IC supports two types of calls - `}<inlineCode parentName="p">{`queries`}</inlineCode>{` and `}<inlineCode parentName="p">{`updates`}</inlineCode>{`. Queries are fast and cannot change state. Updates go through consensus, and will take around 2-4 seconds to complete. `}</p>
    <p>{`As a result of the latency for updates, best practices around modeling your application's performance will have you make updates asynchronously and early. If you can make an update ahead of time and have it already "cached" in your canister's memory, your users will have a better experience requesting that data. Similarly, if your application needs to make an update, it is best to avoid blocking interaction while your update is taking place. Use `}<em parentName="p">{`optimistic rendering`}</em>{` wherever practical, and proceed with your application as if the call has already succeeded.`}</p>
    <h3 {...{
      "id": "a-simple-call"
    }}><a parentName="h3" {...{
        "href": "#a-simple-call"
      }}>{`A simple call`}</a></h3>
    <p>{`Talking to the IC from your application starts with the canister interface. Let's take a very simple one to begin with.`}</p>
    <pre><code parentName="pre" {...{}}>{`# hello.did
service : {
  greet: (text) -> (text);
}
`}</code></pre>
    <p>{`This is a Candid interface. It defines no new special types, and defines a `}<inlineCode parentName="p">{`service`}</inlineCode>{` interface with a single method, `}<inlineCode parentName="p">{`greet`}</inlineCode>{`. Greet accepts a single argument, of type `}<inlineCode parentName="p">{`text`}</inlineCode>{`, and responds with `}<inlineCode parentName="p">{`text`}</inlineCode>{`. Unless labeled as a `}<inlineCode parentName="p">{`query`}</inlineCode>{`, all calls are treated as updates by default.`}</p>
    <p>{`In JS, `}<inlineCode parentName="p">{`text`}</inlineCode>{` maps to a type of `}<inlineCode parentName="p">{`string`}</inlineCode>{`. You can see a full list of Candid types and their JS equivalents at the `}<a parentName="p" {...{
        "href": "https://smartcontracts.org/docs/candid-guide/candid-types.html"
      }}>{`Candid Types`}</a>{` reference.`}</p>
    <p>{`Since this interface is easily typed, we are able to automatically generate a JavaScript interface, as well as TypeScript declarations, for this application. This can be done in two ways. You can manually generate an interface using the `}<inlineCode parentName="p">{`didc`}</inlineCode>{` tool, by going to the `}<a parentName="p" {...{
        "href": "https://github.com/dfinity/candid/releases"
      }}>{`releases`}</a>{` tab of the dfinity/candid repository.`}</p>
    <p>{`In most cases, it is easier to configure your project to have a canister defined in `}<inlineCode parentName="p">{`dfx.json`}</inlineCode>{`, and to generate your declarations automatically using the `}<inlineCode parentName="p">{`dfx generate`}</inlineCode>{` command. `}</p>
    <p>{`For our Hello World example, that looks like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`// dfx.json
{
  "canisters": {
    "hello": {
      "main": "src/hello/main.mo",
      "type": "motoko"
    },
    ...
}
`}</code></pre>
    <p>{`Then when we run `}<inlineCode parentName="p">{`dfx generate`}</inlineCode>{`, dfx will automatically write the following to your src/declarations directory inside your project.`}</p>
    <pre><code parentName="pre" {...{}}>{`|── src
│   ├── declarations
│   │   ├── hello
│   │   │   ├── hello.did
│   │   │   ├── hello.did.d.ts
│   │   │   ├── hello.did.js
│   │   │   ├── hello.most
│   │   │   └── index.js
`}</code></pre>
    <p><inlineCode parentName="p">{`hello.did`}</inlineCode>{` defines your interface, as we saw above, and `}<inlineCode parentName="p">{`hello.most`}</inlineCode>{` is used for upgrade safety. That leaves us with the three remaining files, `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`, `}<inlineCode parentName="p">{`hello.did.js`}</inlineCode>{`, and `}<inlineCode parentName="p">{`hello.did.d.ts`}</inlineCode>{`.`}</p>
    <p>{`Let's start with the simplest, `}<inlineCode parentName="p">{`hello.did.d.ts`}</inlineCode>{`.`}</p>
    <p>{`This file will look something like this:`}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Principal`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'@dfinity/principal'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`ActorMethod`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'@dfinity/agent'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`interface`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`_SERVICE`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'greet'`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`ActorMethod`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token builtin"
        }}>{`string`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token builtin"
        }}>{`string`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`_SERVICE`}</inlineCode>{` export includes a `}<inlineCode parentName="p">{`greet`}</inlineCode>{` method, with typings for an array of arguments and a return type. This will be typed as an `}<a parentName="p" {...{
        "href": "https://agent-js.icp.host/agent/interfaces/ActorMethod.html"
      }}>{`ActorMethod`}</a>{`, which will be a handler that takes arguments and returns a promise that resolves with the type specified in the declarations.`}</p>
    <p>{`Next, let's look at `}<inlineCode parentName="p">{`hello.did.js`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`idlFactory`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="span" {...{
            "className": "token constant"
          }}>{`IDL`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`IDL`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Service`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'greet'`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`IDL`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Func`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`IDL`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Text`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`IDL`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Text`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}</code></pre>
    <p>{`Unlike our `}<inlineCode parentName="p">{`did.d.ts`}</inlineCode>{` declarations, this `}<inlineCode parentName="p">{`idlFactory`}</inlineCode>{` needs to be available during runtime. The `}<inlineCode parentName="p">{`idlFactory`}</inlineCode>{` gets loaded by an `}<a parentName="p" {...{
        "href": "https://agent-js.icp.host/agent/interfaces/Actor.html"
      }}>{`Actor`}</a>{` interface, which is what will handle structuring the network calls according to the IC API and the provided candid spec.`}</p>
    <p>{`This factory again represents a service with a `}<inlineCode parentName="p">{`greet`}</inlineCode>{` method, and the same arguments as before. You may notice, however, that the `}<inlineCode parentName="p">{`IDL.Func`}</inlineCode>{` has a third argument, which here is an empty array. That represents any additional annotations the function may be tagged with, which most commonly will be `}<inlineCode parentName="p">{`"query"`}</inlineCode>{`.`}</p>
    <p>{`And third, we have `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`, which will pull those pieces together and set up a customized actor with your smart contract's interface. This does a few things, like using `}<inlineCode parentName="p">{`process.env`}</inlineCode>{` variables to determine the ID of the canister, based on which deploy context you are using, but the most important aspect is in the `}<inlineCode parentName="p">{`createActor`}</inlineCode>{` export.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`createActor`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`canisterId`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` agent `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`HttpAgent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`agentOptions `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Fetch root key for certificate validation during development`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`NODE_ENV`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`!==`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"production"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fetchRootKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`catch`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`err`}</span><span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`warn`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Unable to fetch root key. Check to ensure that your local replica is running"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`error`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`err`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Creates an actor with using the candid interface and the HttpAgent`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Actor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`idlFactory`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    canisterId`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`actorOptions`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`This constructor first creates a `}<inlineCode parentName="p">{`HttpAgent`}</inlineCode>{`, which is wraps the JS `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` API and uses it to encode calls through the public API. We also optionally fetch the root key of the replica, for non-mainnet deployments. Finally, we create an Actor using the automatically generated interface for the canister we will call, passing it the `}<inlineCode parentName="p">{`canisterId`}</inlineCode>{` and the `}<inlineCode parentName="p">{`HttpAgent`}</inlineCode>{` we have initialized.`}</p>
    <p>{`This `}<inlineCode parentName="p">{`Actor`}</inlineCode>{` instance is now set up to call all of the service methods as methods. Once this is all set up, like it is by default in the `}<inlineCode parentName="p">{`dfx new`}</inlineCode>{` template, you can simply run `}<inlineCode parentName="p">{`dfx generate`}</inlineCode>{` whenever you make changes to your canister API, and the full interface will automatically stay in sync in your frontend code.`}</p>
    <h2 {...{
      "id": "browser"
    }}><a parentName="h2" {...{
        "href": "#browser"
      }}>{`Browser`}</a></h2>
    <p>{`The browser context is the easiest to account for. The `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` API is available, and most apps will have an easy time determining whether they need to talk to `}<inlineCode parentName="p">{`https://ic0.app`}</inlineCode>{` or a local replica, depending on their URL. `}</p>
    <p>{`When you are building apps that run in the browser, here are some things to consider:`}</p>
    <h3 {...{
      "id": "performance"
    }}><a parentName="h3" {...{
        "href": "#performance"
      }}>{`Performance`}</a></h3>
    <p>{`Updates to the IC may feel slow to your users, at around 2-4 seconds. When you are building your application, take that latency into consideration, and consider following some best practices:`}</p>
    <ul>
      <li parentName="ul">{`Avoid blocking UI interactions while you wait for the result of your update. Instead, allow users to continuing to make other updates and interactions, and inform your users of success asyncronously. `}</li>
      <li parentName="ul">{`Try to avoid making inter-canister calls. If the backend needs to talk to other canisters, the duration can add up quickly.`}</li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`Promise.all`}</inlineCode>{` to make multiple calls in a batch, instead of making them one-by-one`}</li>
      <li parentName="ul">{`If you need to fetch assets or data, you can make direct `}<inlineCode parentName="li">{`fetch`}</inlineCode>{` calls to the `}<inlineCode parentName="li">{`raw.ic0.app`}</inlineCode>{` endpoint for canisters`}</li>
    </ul>
    <h2 {...{
      "id": "nodejs"
    }}><a parentName="h2" {...{
        "href": "#nodejs"
      }}>{`Node.js`}</a></h2>
    <h3 {...{
      "id": "configuration"
    }}><a parentName="h3" {...{
        "href": "#configuration"
      }}>{`Configuration`}</a></h3>
    <p>{`You may need to do some additional configuration for your node.js application, using the default declarations we provide from dfx generate. This is because there are a couple features that are typically present in the browser context that may not be available in your Node.js context. Fetch is now available in Node 18, so that may not work out of the box. However, in older versions, you may need to configure fetch to be available in the global context, as well as the `}<inlineCode parentName="p">{`TextEncoder`}</inlineCode>{` API.`}</p>
    <p>{`In Node 18 - the setup is fairly simple. You need to make the following changes once you've generated your declarations:`}</p>
    <ol>
      <li parentName="ol">{`Rename the `}<inlineCode parentName="li">{`.js`}</inlineCode>{` files to `}<inlineCode parentName="li">{`.mjs`}</inlineCode></li>
      <li parentName="ol">{`Comment out the last line exporting `}<inlineCode parentName="li">{`hello`}</inlineCode></li>
      <li parentName="ol">{`Either provide the canisterId through a process variable or import it directly`}</li>
      <li parentName="ol">{`Pass the host during the `}<inlineCode parentName="li">{`createActor`}</inlineCode>{` method`}</li>
    </ol>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// script.mjs`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Note - files were re-named from js to mjs`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createActor `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"../declarations/hello/index.mjs"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`canisterIds`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"../../.dfx/local/canister_ids.json"`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`assert`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` type`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"json"`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` canisterId `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` canisterIds`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`hello`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`local`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` hello `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`canisterId`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  agentOptions`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` 
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// fetch polyfill would go here if needed`}</span>{`
    host`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"http://127.0.0.1:8000"`}</span>{` 
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

hello`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`greet`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Alice"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`then`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`result`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`result`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`This will work out of the box, but you may also prefer to use a bundler to support TypeScript or other language features. `}</p>
    <h2 {...{
      "id": "bundlers"
    }}><a parentName="h2" {...{
        "href": "#bundlers"
      }}>{`Bundlers`}</a></h2>
    <p>{`We recommend using a bundler to assemble your code for convenience and less troubleshooting. We provide a standard Webpack config, but you may also turn to Rollup, Vite, Parcel, or others. For this pattern, we recommend running a script to generate `}<inlineCode parentName="p">{`.env.development`}</inlineCode>{` and `}<inlineCode parentName="p">{`.env.production`}</inlineCode>{` environment variable files for your canister ids, which is a fairly standard approach for bundlers, and can be easily supported using `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/dotenv"
      }}>{`dotenv`}</a>{`. Here is an example script you can run to map those files:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// setupEnv.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` fs `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`require`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"fs"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` path `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`require`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"path"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`initCanisterEnv`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` localCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` prodCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`try`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    localCanisters `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`require`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`path`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`resolve`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
      `}<span parentName="code" {...{
          "className": "token string"
        }}>{`".dfx"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"local"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"canister_ids.json"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`catch`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`error`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"No local canister_ids.json found"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`try`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    prodCanisters `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`require`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`path`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`resolve`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"canister_ids.json"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`catch`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`error`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"No production canister_ids.json found"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` network `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{`
    process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`DFX_NETWORK`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`||`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`NODE_ENV`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"production"`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"ic"`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"local"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` canisterConfig `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` network `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"local"`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` localCanisters `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` prodCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` localMap `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` localCanisters
    `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` `}<span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`Object`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`entries`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`localCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`reduce`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`prev`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` current`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`canisterName`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` canisterDetails`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` current`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        prev`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`canisterName`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toUpperCase`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"_CANISTER_ID"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{`
          canisterDetails`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`network`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` prev`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword nil"
        }}>{`undefined`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` prodMap `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` prodCanisters
    `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` `}<span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`Object`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`entries`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`prodCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`reduce`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`prev`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` current`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`canisterName`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` canisterDetails`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` current`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        prev`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`canisterName`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toUpperCase`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"_CANISTER_ID"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{`
          canisterDetails`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`network`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` prev`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword nil"
        }}>{`undefined`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`localMap`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` prodMap`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`localCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` prodCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`initCanisterEnv`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`localCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` localTemplate `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`Object`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`entries`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`localCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`reduce`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`start`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` next`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`key`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` val`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` next`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span>{`start`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`key`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`val`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`start `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`??`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`""`}</span><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`
          `}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`key`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`val`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  fs`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`writeFileSync`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`".env.development"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` localTemplate`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`prodCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` prodTemplate `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`Object`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`entries`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`prodCanisters`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`reduce`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`start`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` next`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`key`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` val`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` next`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span>{`start`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`key`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`val`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`start `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`??`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`""`}</span><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`
        `}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`key`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`val`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  fs`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`writeFileSync`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`".env"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` localTemplate`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`Then, you can add `}<inlineCode parentName="p">{`"prestart"`}</inlineCode>{` and `}<inlineCode parentName="p">{`"prebuild"`}</inlineCode>{` commands of `}<inlineCode parentName="p">{`dfx generate; node setupEnv.js`}</inlineCode>{`. Follow documentation for your preferred bundler on how to work with environment variables.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      